import React from 'react';
import { getImageUrlWithSportType, getScoreFootball } from 'hooks';

function ItemShowScores({ currentMatchId, match, sportType }) {
  // const isActive = currentIndex === index;
  const isActive = currentMatchId === match.matchId;

  return match && match.matchId ? (
    <div
      id={`${currentMatchId} - ${match.matchId}`}
      className={`as-scores-wrapper ${isActive ? 'active' : ''}`}
      style={{
        opacity: isActive ? 1 : 0,
        visibility: isActive ? 'visible' : 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
      }}
    >
      {/* Timeline */}
      <div className="as-wrapper-item">
        <div className="as-timeline-wrapper">
          <img
            className="as-timeline-img"
            src={match.isScoreCancel ? '/goal-cancel.png' : '/icon-time.png'}
            alt="Timeline"
          />
          {match.timeScored && (
            <div className="as-timeline-minutes">
              <span>{`${match.timeScored}'`}</span>
              {/* <span>{`${match.matchId ?? 'N/A'}'`}</span> */}
            </div>
          )}
        </div>

        {/* Home Team */}
        <div className="as-wrapper-player as-home">
          <div className="as-item-info">
            <span>{match.homeTeam?.name}</span>
          </div>
          <div className="as-item-img">
            <img
              className="as-img-player"
              src={getImageUrlWithSportType(sportType, 'team', match.homeTeam ? match.homeTeam?.id : 'default')}
              alt={match.homeTeam?.name}
            />
          </div>
        </div>
      </div>

      {/* Score */}
      <div className="as-score-wrapper">
        <span className={`${match.isHomeGoaled ? 'as-goal' : ''}`}>
          {getScoreFootball(match.homeScore)}
        </span>
        <span>:</span>
        <span className={`${match.isHomeGoaled ? '' : 'as-goal'}`}>
          {getScoreFootball(match.awayScore)}
        </span>
      </div>

      {/* Away Team */}
      <div className="as-wrapper-item">
        <div className="as-wrapper-player as-away">
          <div className="as-item-info">
            <span>{match.awayTeam?.name}</span>
          </div>
          <div className="as-item-img">
            <img
              className="as-img-player"
              src={getImageUrlWithSportType(sportType, 'team', match.awayTeam ? match.awayTeam?.id : 'default')}
              alt={match.awayTeam?.name}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ItemShowScores;
